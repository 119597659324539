import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2><a id="character-classes"></a>Character Classes</h2>
    <h3><a id="fighter"></a>FIGHTER</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td className="line"> </td>
        </tr>
      </tbody>
    </table>
    <p className="initial"><a href="http://www.wizards.com/dnd/images/ph35_gallery/PHB35_PG37_WEB.jpg"><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
          "border": "0px solid",
          "width": "73px",
          "height": "75px"
        }} /></a><b>Alignment</b>:
Any.</p>
    <p><b>Hit Die</b>: d10.</p>
    <h6>Class Skills</h6>
    <p className="initial">The fighter&rsquo;s class skills (and the key
ability for each skill) are <a href="skillsAll.html#climb" style={{
        "color": "rgb(87, 158, 182)"
      }}>Climb</a>
(Str), <a href="skillsAll.html#craft" style={{
        "color": "rgb(87, 158, 182)"
      }}>Craft</a>
(Int), <a href="skillsAll.html#handle-animal" style={{
        "color": "rgb(87, 158, 182)"
      }}>Handle Animal</a> (Cha), <a href="skillsAll.html#intimidate" style={{
        "color": "rgb(87, 158, 182)"
      }}>Intimidate</a>
(Cha), <a href="skillsAll.html#jump" style={{
        "color": "rgb(87, 158, 182)"
      }}>Jump</a>
(Str), <a href="skillsAll.html#ride" style={{
        "color": "rgb(87, 158, 182)"
      }}>Ride</a>
(Dex), and <a href="skillsAll.html#swim" style={{
        "color": "rgb(87, 158, 182)"
      }}>Swim</a> (Str).</p>
    <p><b>Skill Points at 1st Level</b>: (2 + Int modifier) x 4.</p>
    <p><b>Skill Points at Each Additional Level</b>: 2 + Int modifier.</p>
    <p><a id="table-the-fighter"></a><b>Table: The Fighter</b></p>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "60%"
    }}>
      <tbody>
        <tr valign="bottom">
          <th style={{
            "width": "12%"
          }}>Level</th>
          <th style={{
            "width": "22%"
          }}>Base
Attack Bonus</th>
          <th style={{
            "width": "12%"
          }}>Fort
Save</th>
          <th style={{
            "width": "12%"
          }}>Ref
Save</th>
          <th style={{
            "width": "12%"
          }}>Will
Save</th>
          <th>Special</th>
        </tr>
        <tr className="odd-row">
          <td>1st</td>
          <td>+1</td>
          <td>+2</td>
          <td>+0</td>
          <td>+0</td>
          <td>Bonus feat</td>
        </tr>
        <tr>
          <td>2nd</td>
          <td>+2</td>
          <td>+3</td>
          <td>+0</td>
          <td>+0</td>
          <td>Bonus feat</td>
        </tr>
        <tr className="odd-row">
          <td>3rd</td>
          <td>+3</td>
          <td>+3</td>
          <td>+1</td>
          <td>+1</td>
          <td>&nbsp;</td>
        </tr>
        <tr>
          <td>4th</td>
          <td>+4</td>
          <td>+4</td>
          <td>+1</td>
          <td>+1</td>
          <td>Bonus feat </td>
        </tr>
        <tr className="odd-row">
          <td>5th</td>
          <td>+5</td>
          <td>+4</td>
          <td>+1</td>
          <td>+1</td>
          <td>&nbsp;</td>
        </tr>
        <tr>
          <td>6th</td>
          <td>+6/+1</td>
          <td>+5</td>
          <td>+2</td>
          <td>+2</td>
          <td>Bonus feat</td>
        </tr>
        <tr className="odd-row">
          <td>7th</td>
          <td>+7/+2</td>
          <td>+5</td>
          <td>+2</td>
          <td>+2</td>
          <td>&nbsp;</td>
        </tr>
        <tr>
          <td>8th</td>
          <td>+8/+3</td>
          <td>+6</td>
          <td>+2</td>
          <td>+2</td>
          <td>Bonus feat</td>
        </tr>
        <tr className="odd-row">
          <td>9th</td>
          <td>+9/+4</td>
          <td>+6</td>
          <td>+3</td>
          <td>+3</td>
          <td>&nbsp;</td>
        </tr>
        <tr>
          <td>10th</td>
          <td>+10/+5</td>
          <td>+7</td>
          <td>+3</td>
          <td>+3</td>
          <td>Bonus feat</td>
        </tr>
        <tr className="odd-row">
          <td>11th</td>
          <td>+11/+6/+1</td>
          <td>+7</td>
          <td>+3</td>
          <td>+3</td>
          <td>&nbsp;</td>
        </tr>
        <tr>
          <td>12th</td>
          <td>+12/+7/+2</td>
          <td>+8</td>
          <td>+4</td>
          <td>+4</td>
          <td>Bonus feat</td>
        </tr>
        <tr className="odd-row">
          <td>13th</td>
          <td>+13/+8/+3</td>
          <td>+8</td>
          <td>+4</td>
          <td>+4</td>
          <td>&nbsp;</td>
        </tr>
        <tr>
          <td>14th</td>
          <td>+14/+9/+4</td>
          <td>+9</td>
          <td>+4</td>
          <td>+4</td>
          <td>Bonus feat</td>
        </tr>
        <tr className="odd-row">
          <td>15th</td>
          <td>+15/+10/+5</td>
          <td>+9</td>
          <td>+5</td>
          <td>+5</td>
          <td>&nbsp;</td>
        </tr>
        <tr>
          <td>16th</td>
          <td>+16/+11/+6/+1</td>
          <td>+10</td>
          <td>+5</td>
          <td>+5</td>
          <td>Bonus feat</td>
        </tr>
        <tr className="odd-row">
          <td>17th</td>
          <td>+17/+12/+7/+2</td>
          <td>+10</td>
          <td>+5</td>
          <td>+5</td>
          <td>&nbsp;</td>
        </tr>
        <tr>
          <td>18th</td>
          <td>+18/+13/+8/+3</td>
          <td>+11</td>
          <td>+6</td>
          <td>+6</td>
          <td>Bonus feat</td>
        </tr>
        <tr className="odd-row">
          <td>19th</td>
          <td>+19/+14/+9/+4</td>
          <td>+11</td>
          <td>+6</td>
          <td>+6</td>
          <td>&nbsp;</td>
        </tr>
        <tr>
          <td className="last-row">20th</td>
          <td className="last-row">+20/+15/+10/+5</td>
          <td className="last-row">+12</td>
          <td className="last-row">+6</td>
          <td className="last-row">+6</td>
          <td className="last-row">Bonus feat</td>
        </tr>
      </tbody>
    </table>
    <h6><a id="fighter-class-features"></a>Class Features</h6>
    <p className="initial">All of the following are class features of the
fighter.</p>
    <p><b>Weapon and Armor Proficiency</b>: A fighter is proficient
with all simple and martial weapons and with all armor (heavy, medium,
and light) and shields (including tower shields).</p>
    <p><a id="fighter-bonus-feats"></a><b>Bonus Feats</b>: At 1st level, a fighter gets a bonus
combat-oriented feat in addition to the feat that any 1st-level
character gets and the bonus feat granted to a human character. The
fighter gains an additional bonus feat at 2nd level and every two
fighter levels thereafter (4th, 6th, 8th, 10th, 12th, 14th, 16th, 18th,
and 20th). These bonus feats must be drawn from the feats noted as
fighter bonus feats. A fighter must still meet all prerequisites for a
bonus feat, including ability score and base attack bonus minimums.</p>
    <p>These bonus feats are in addition to the feat that a character
of any class gets from advancing levels. A fighter is not limited to
the <a href="featsFtb.html" style={{
        "color": "rgb(87, 158, 182)"
      }}>list of
fighter bonus feats</a> when choosing these feats.</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      